<template>
    <el-form :model="ruleForm" :rules="rules" label-width="auto" ref="formRef">
        <el-form-item label="资产名称" prop="assetsName">
            <el-input
                v-model="ruleForm.assetsName"
                placeholder="请输入资产名称"
                clearable
                maxlength="20"
                show-word-limit
            ></el-input>
        </el-form-item>
        <el-form-item label="资产品类" prop="categoryId">
            <el-select
                v-model="ruleForm.categoryId"
                clearable
                filterable
                placeholder="请选择资产品类"
            >
                <el-option
                    v-for="(item, index) in list"
                    :value="item.value"
                    :label="item.label"
                    :key="index"
                />
            </el-select>
        </el-form-item>
        <el-form-item label="资产类型" prop="assetsType">
            <el-select
                v-model="ruleForm.assetsType"
                clearable
                filterable
                placeholder="请选择资产类型"
            >
                <el-option value="1" label="固定资产"></el-option>
                <el-option value="2" label="耗材资产"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="资产品牌" prop="assetsBrand">
            <el-input
                v-model="ruleForm.assetsBrand"
                placeholder="请输入资产品牌"
                clearable
                maxlength="40"
                show-word-limit
            ></el-input>
        </el-form-item>

        <el-form-item label="资产型号" prop="assetsModel">
            <el-input
                v-model="ruleForm.assetsModel"
                placeholder="请输入资产型号"
                clearable
                maxlength="40"
                show-word-limit
            ></el-input>
        </el-form-item>
        <el-form-item label="资产序列号" prop="assetsSerialNumber">
            <el-input
                v-model="ruleForm.assetsSerialNumber"
                placeholder="请输入资产序列号"
                clearable
                maxlength="40"
                show-word-limit
            ></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="assetsUnit">
            <el-input
                v-model="ruleForm.assetsUnit"
                placeholder="请输入计量单位"
                clearable
                maxlength="5"
                show-word-limit
            ></el-input>
        </el-form-item>

        <div style="text-align: center">
            <xk-button @click="submitForm" type="primary">确定</xk-button>
            <xk-button @click="handleClose" type="info">取消</xk-button>
        </div>
    </el-form>
</template>

<script>
export default {
    props: {
        ruleForm: {
            type: Object,
        },
        rules: {
            type: Object
        },
        list: {
            type: Array
        },
    },
    methods: {
        submitForm() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.$emit('submit')
                }
            })
        },
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style scoped lang="scss">
.el-select {
    width: 100%;
}
</style>
