<template>
    <el-form class="common-form" label-position="right" label-width="94px">
        <el-row :gutter="40" style="margin-bottom: 18px">
            <el-col :span="12">
                <div class="common-form-title">资产基本信息</div>
                <el-form-item label="资产编号">{{ data.assetsCode || '--' }}</el-form-item>
                <el-form-item label="资产名称">{{ data.assetsName || '--' }} </el-form-item>
                <el-form-item label="仓库名称">{{ data.storeName || '--' }}</el-form-item>
                <el-form-item label="资产品类">{{ data.categoryName || '--' }}</el-form-item>
                <el-form-item label="资产类型">{{ data.assetsType == '1' ? '固定资产' : '耗材资产' || '--' }}</el-form-item>
                <el-form-item label="资产品牌">{{ data.assetsBrand || '--' }}</el-form-item>
                <el-form-item label="资产型号">{{ data.assetsModel || '--' }}</el-form-item>
                <el-form-item label="资产序列号">{{ data.assetsSerialNumber || '--' }}</el-form-item>
                <el-form-item label="计量单位">{{ data.assetsUnit || '--' }}</el-form-item>
            </el-col>
            <el-col :span="12">
                <div class="common-form-title">资产状态</div>
                <el-form-item label="资产总数">{{ data.assetsCount }}</el-form-item>
                <el-form-item label="资产总额">{{ data.assetsTotal ? data.assetsTotal.toLocaleString() : 0 }}</el-form-item>
                <el-form-item label="入库次数">{{ data.assetsInTimes }}</el-form-item>
                <el-form-item label="出库次数">{{ data.assetsOutTimes }}</el-form-item>
                <el-form-item label="出库数量">{{ data.assetsOutTotal }}</el-form-item>
                <el-form-item label="维修次数">{{ data.repairTimes }}</el-form-item>
                <el-form-item label="维修金额">{{ data.repairTotal }}</el-form-item>
            </el-col>
        </el-row>
        <div class="common-form-title">资产变动记录</div>
        <table-data
            :config="table_config"
            :tableData="data.result"
        />
    </el-form>
</template>

<script>
import TableData from "@/components/scrollWrapper/Sub/TableData"
export default {
    props: {
        data: {
            type: Object
        }
    },
    components: {
        TableData
    },
    computed: {

    },
    data() {
        return {
            table_config: {
                thead: [
                    {
                        label: "日期",
                        prop: "operateTime",
                        labelWidth: '160'
                    },
                    {
                        label: "操作人",
                        prop: "createName",
                    },
                    {
                        label: "操作人所在部门",
                        prop: "deptName",
                    },
                    {
                        type: "function",
                        label: "变动类型",
                        align: "center",
                        callBack(row) {
                            return {
                                1: "入库",
                                2: "出库",
                                3: "报修",
                                4: "归还",
                            }[+row.recordType];
                        },
                    },
                    {
                        label: "详情",
                        prop: "info",
                    }
                ],
                height: '400',
                check: false,
                leftEmpty: false
            },
        }
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">
.el-form-item {
    margin-bottom: 0;
}
</style>
