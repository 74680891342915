<template>
    <div class="common-form">
        <el-form :model="ruleForm" :rules="rules" label-width="94px" ref="formRef">
            <div class="common-form-title form-info" >资产信息</div>
            <el-form-item label="资产编号" class="form-info">{{ showData.assetsCode || "--" }}</el-form-item>
            <el-form-item label="资产名称" class="form-info">{{ showData.assetsName || "--" }}</el-form-item>
            <el-form-item label="计量单位">{{ showData.assetsUnit || "--" }}</el-form-item>
            <div class="common-form-title">资产入库</div>
            <el-form-item label="资产供应商" prop="assetsSupplier">
                <el-input
                    v-model="ruleForm.assetsSupplier"
                    placeholder="请输入资产供应商"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="采购日期" prop="buyTime">
                <el-date-picker
                    v-model="ruleForm.buyTime"
                    type="datetime"
                    placeholder="请选择采购日期"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="采购单价" prop="unitPrice">
                <el-input
                    v-model="ruleForm.unitPrice"
                    placeholder="请输入采购单价（元）"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <el-form-item label="入库数量" prop="inNumber">
                <el-input
                    v-model="ruleForm.inNumber"
                    placeholder="请输入入库数量"
                    clearable
                    class="input-width-medium"
                />
            </el-form-item>
            <div style="text-align: right">
                <el-button @click="handleClose">取消</el-button>
                <el-button @click="submitForm" type="primary">确定</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        showData: {
            type: Object,
        },
        ruleForm: {
            type: Object,
        },
        rules: {
            type: Object
        },
        list: {
            type: Array
        },
    },
    data() {
        return {
            num: 20
        }
    },
    methods: {
        submitForm() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.$emit('submit')
                }
            })
        },
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style scoped lang="scss">
.form-info {
    margin-bottom: 10px;
}
</style>
