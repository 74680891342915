<template>
    <div>
        <global-page-back
            detailPageTitle="出入库流水"
            @handlerGlobalType="clickBackBtn"
        >
            <template #globalPageBackTabs>
                <div class="tabs-list">
                    <div :class="['tabs-item',{ current: activeName === 1 }]" @click="changeTabs(1)">入库明细</div>
                    <div :class="['tabs-item',{ current: activeName === 2 }]" @click="changeTabs(2)">出库明细</div>
                    <div :class="['tabs-item',{ current: activeName === 3 }]" @click="changeTabs(3)">报修明细</div>
                </div>
            </template>
        </global-page-back>
        <div class="expand-filter-wrapper content-main">
            <div class="filter-wrap">
                <expand-filter
                    :formData="formData"
                    marginBottom="0px"
                    :closeWidth="formData.closeWidth"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                >
            </expand-filter>
            <div class="button-line"></div>
            <div>
                <el-button type="enquiry" @click="handleExport">导出</el-button>
            </div>

            </div>
            <table-data
                v-loading="loadingTable"
                ref="table"
                :config="table_config"
                :tableData="table_data"
            >
                <template v-slot:operation="{data}">
                    <xk-button
                        v-if="data.recordType !== 4"
                        type="danger"
                        size="mini"
                        v-has-permi="['assetWarehouseAssets:waterRecord:delete']"
                        @click="handleDel(data)"
                    >删除</xk-button
                    >
                </template>
            </table-data>
            <Pagination
                :total="total"
                :page.sync="listQuery.pageNum"
                :limit.sync="listQuery.pageRow"
                @pagination="getList()"
            />
        </div>
    </div>
</template>

<script>
import {
    // 业务组件
    Pagination,
    DialogWrapper,
    // 工具函数
} from 'common-local';
import TableData from "../Sub/TableData"
import ExpandFilter from "../Sub/ExpandFilter";
import warehouseFlow from "@/assets/data/warehouseFlow";
import { AssetWarehouseAssetsModel } from '@/models/AssetWarehouseAssets.js'
import onResize from "@/mixins/onResize"

import store from '@/store'
import { downloadFile } from "@/libs/utils";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
const schoolId = store.state.schoolId

export default {
    mixins: [onResize],
    props: {
        storeData: {
            type: Object
        }
    },
    components: {
        GlobalPageBack,
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
    },
    data() {
        return {
            warehouseFlow,
            activeName: 1,
            formData: {
            },
            table_config: {
                height: '',
                check: false
            },
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: schoolId,
                recordType: '',
                storeId: '',
                categoryId: '',
                assetsName: '',
                assetsCode: '',
                assetsType: '',
                startTime: '',
                endTime: '',
                assetsSupplier: '',
                declarer: '',
                fixPerson: '',
                collectPerson: ''
            },
            // 表格数据
            table_data: [],
            // 分页器
            total: 0,
            // 功能组件
            loadingTable: true,
            categoryList: [],
            departmentPeopleInfo: {
                storeId: '',
                schoolId: '',
            },
            departmentPeopleData: []
        }
    },
    created() {
        this.initializationAssignment()
        this.initPageData();
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        initializationAssignment () {
            this.departmentPeopleInfo.storeId = this.storeData.id;
            this.departmentPeopleInfo.schoolId = this.$store.state.schoolId;

        },
        async initPageData() {
            await this.getDepartmentPeople()
            await this.getSchoolStoreCategory()
            await this.changeTabs(1)
        },
        changeTabs(t) {
            this.$set(this.table_config, 'thead', this.warehouseFlow[t].table_config.thead)
            this.formData = this.warehouseFlow[t].formData
            this.formData.data.forEach((item) => {
                if (item.key === 'categoryId') {
                    item.list = this.categoryList
                }
                if (item.key === 'collectPerson') {
                    item.list = this.departmentPeopleData;
                }
            })
            this.listQuery = this.$options.data().listQuery
            this.listQuery.storeId = this.storeData.id
            this.listQuery.recordType = this.activeName = t
            this.table_data = []
            this.total = 0
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.doLayout()
            })
            this.getList()
        },
        // table 分页器
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            this.loadingTable = true
            this._fet('/school/schoolStoreAssetsRecord/list', this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    console.log(res.data.data.list,'res.data.data.list')
                    this.total = res.data.data.totalCount

                    if (this.activeName === 3) {
                        res.data.data.list.forEach((item) => {
                            if (item.operateTime) {
                                this.$set(item, 'newOperateTime', item.operateTime.substring(0, item.operateTime.length - 3))
                            } else {
                                this.$set(item, 'newOperateTime', '-')
                            }
                        })
                    }
                    this.table_data = res.data.data.list;
                    this.$_resizeHandler()
                })
                this.loadingTable = false
            })
        },
        /**
         * @Description: 获取部门/人 下拉数据
         * @DoWhat: 获取部门/人 下拉数据
         * @UseScenarios: 获取部门/人 下拉数据
         * @Attention: 获取部门/人 下拉数据
         * @Author: 武东超
         * @Date: 2022-12-05 15:02:45
         */
        getDepartmentPeople () {
            const assetWarehouseAssetsModel = new AssetWarehouseAssetsModel();
            assetWarehouseAssetsModel.getDepartmentPeople(this.departmentPeopleInfo).then((res) => {
                if (res.data.code === '200') {
                    this.departmentPeopleData = res.data.data.map(item => ({
                        label: item.name,
                        value: item.collectPerson || item.collectDept
                    }));
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        // 获取资产品类
        async getSchoolStoreCategory() {
            await this._fet('/school/schoolStoreCategory/listByCondition', {
                schoolId: schoolId,
                storeId: this.storeData.id
            }).then((res) => {
                this.handleRes(res, () => {
                    this.categoryList = res.data.data.map(i => ({
                        label: i.categoryName,
                        value: i.id
                    }))
                })
            })
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case 'primary':
                    this.formData.data.forEach((item) => {
                        if (item.key === 'time') {
                            if (item.value && item.value.length === 2) {
                                this.listQuery.startTime = item.value[0] + ' 00:00:00'
                                this.listQuery.endTime = item.value[1] + ' 23:59:59'
                            } else {
                                this.listQuery.startTime = ''
                                this.listQuery.endTime = ''
                            }
                        } else {
                            this.listQuery[item.key] = item.value;
                        }
                    })
                    this.getList(1);
                    break;
                case 'reset':
                    this.formData.data.forEach((item) => {
                        item.value = '';
                        this.listQuery[item.key] = ''
                    })
                    this.listQuery.startTime = ''
                    this.listQuery.endTime = ''
                    this.getList(1);
                    break;
                case 'export':
                    this.handleExport()
                    break;
                default:
                    break;
            }
        },
        // 返回资产管理列表页
        clickBackBtn(){
            this.$emit('changeComp',{
                index: 0,
                data: {}
            })
        },
        handleExport() {
            let obj = {
                schoolId: schoolId,
                storeId: this.listQuery.storeId,
                recordType: this.listQuery.recordType
            }
            this.formData.data.forEach((item) => {
                if (item.value && item.value.length === 2) {
                    obj['startTime'] = item.value[0]
                    obj['endTime'] = item.value[1]
                } else {
                    obj[item.key] = item.value
                }
            })
            downloadFile({url: '/school/schoolStoreAssetsRecord/export', form: obj}, () => {

            },() => {

            })
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
        /*
            删除
         */
        handleDel(data) {
            console.log('data',data);
            this.$confirm("请谨慎删除，流水删除后，无法恢复！", "删除确认", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet("/school/schoolStoreAssetsRecord/delAssetsRecord", {id: data.id}).then(
                        (res) => {
                            this.handleRes(res, () => {
                                this.$message.success("删除成功!");
                                this.getList(1);
                            });
                        },
                    );
                })
                .catch(() => {});
        },
    },
}
</script>
<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    // justify-content: space-between;
    // text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;
    .expand-filter {
        padding: 0;
    }
}
.tabs-list {
    position: relative;
    padding-left: 72px;
    display: flex;
    height: 48px;
    line-height: 48px;

    &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 24px;
        background: #D4D6D9;
        border-radius: 1px;
        left: 58px;
        top: 12px;
    }

    .tabs-item {
        width: 96px;
        position: relative;
        text-align: center;
        font-size: 15px;
        color: #606C80;
        cursor: pointer;
    }

    .tabs-item.current {
        color: #2B2E33;
        font-weight: bold;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            height: 3px;
            width: 30px;
            background: #3C7FFF;
            border-radius: 2px;
        }
    }
}
</style>
